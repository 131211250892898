<template>
  <div>
    <user-stats />
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <amount-stat
          :key="componentPayStatKey"
          :change="$store.state.payment.globalStatValue[0].percent"
          :loading="loadingGlobalStatPay"
          :color="$store.state.payment.globalStatValue[0].color"
          :icon="icons.mdiCashPlus"
          :statistics="$store.state.payment.globalStatValue[0].quantity"
          :stat-title="$store.state.payment.globalStatKeys[0]"
          :subtitle="$store.state.payment.globalStatValue[0].fee"
          :amount="$store.state.payment.globalStatValue[0].value"
          @mount="getGlobalPayStat"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <amount-stat
          :key="componentPayStatKey"
          :change="$store.state.payment.globalStatValue[1].percent"
          :loading="loadingGlobalStatPay"
          :color="$store.state.payment.globalStatValue[1].color"
          :icon="icons.mdiCashMinus"
          :statistics="$store.state.payment.globalStatValue[1].quantity"
          :stat-title="$store.state.payment.globalStatKeys[1]"
          :subtitle="$store.state.payment.globalStatValue[1].fee"
          :amount="$store.state.payment.globalStatValue[1].value"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <DoughnutChart
          :key="componentReasonKey"
          title="Paiments en fonction du motif"
          subtitle="Montant totaux des paiements en fonction du motif"
          :loading="loadingReason"
          :date-min="$store.state.payment.filterReason.min_date"
          :date-max="$store.state.payment.filterReason.max_date"
          :dat="$store.state.payment.dataReasonValueY"
          :cat="$store.state.payment.catReasonValueX"
          :color="$store.state.payment.dataReasonValueColor"
          :percent="$store.state.payment.dataReasonValuePercent"
          @mount="filterReason"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <DoughnutChart
          :key="componentValidityKey"
          title="Réservations validées"
          subtitle="Montant totaux des réservations validées"
          :loading="loadingValidity"
          :date-min="$store.state.payment.filterValidity.min_date"
          :date-max="$store.state.payment.filterValidity.max_date"
          :dat="$store.state.payment.dataValidityValueY"
          :cat="$store.state.payment.catValidityValueX"
          :color="$store.state.payment.dataValidityValueColor"
          :percent="$store.state.payment.dataValidityValuePercent"
          @mount="filterValidity"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <DoughnutChart
          :key="componentDepositValueKey"
          title="Réservations en valeur"
          subtitle="Statistiques en valeur sur les réservations"
          :loading="loadingDeposit"
          :date-min="$store.state.payment.filterDepositValueStat.min_date"
          :date-max="$store.state.payment.filterDepositValueStat.max_date"
          :dat="$store.state.payment.dataDepositValueY"
          :cat="$store.state.payment.catDepositValueX"
          :color="$store.state.payment.dataDepositValueColor"
          :percent="$store.state.payment.dataDepositValuePercent"
          @mount="filterBooking"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <DoughnutChart
          :key="componentWithdrawValueKey"
          title="Retraits des fonds"
          subtitle="Retraits des fonds par les membres"
          :loading="loadingWithdrawValue"
          :date-min="$store.state.payment.filterWithdrawValueStat.min_date"
          :date-max="$store.state.payment.filterWithdrawValueStat.max_date"
          :dat="$store.state.payment.dataWithdrawValueY"
          :cat="$store.state.payment.catWithdrawValueX"
          :color="$store.state.payment.dataWithdrawValueColor"
          :percent="$store.state.payment.dataWithdrawValuePercent"
          @mount="filterWithdrawValue"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <bar-chart
          :key="componentKey"
          :title="'Réservations'"
          :subtitle="'Statistiques sur les réservations'"
          :loading="loading"
          :dat="$store.state.payment.dataY"
          :cat="$store.state.payment.catX"
          :date-min="$store.state.payment.filterStat.min_date"
          :date-max="$store.state.payment.filterStat.max_date"
          @mount="filterPay"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <bar-chart
          :key="componentWithdrawKey"
          :title="'Retraits'"
          :subtitle="'Statistiques sur les retraits des fonds'"
          :loading="loadingWithdraw"
          :dat="$store.state.payment.dataWithdrawY"
          :cat="$store.state.payment.catWithdrawX"
          :date-min="$store.state.payment.filterWithdraw.min_date"
          :date-max="$store.state.payment.filterWithdraw.max_date"
          @mount="filterWithdraw"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiLabelVariantOutline, mdiCashMinus, mdiCashPlus } from '@mdi/js'
import UserStats from '@core/components/statistics/UserStats'
import BarChart from '@core/components/statistics/BarChart'
import { onMounted, ref, watch } from '@vue/composition-api/dist/vue-composition-api'
import DatePickerCustom from '@core/components/datepicker/DatePickerCustom'
import DoughnutChart from '@core/components/statistics/DoughnutChart'
import AmountStat from '@core/components/statistics/AmountStat'
import store from '@/store'

export default {
  components: {
    AmountStat,
    DoughnutChart,
    DatePickerCustom,
    BarChart,
    UserStats,
  },
  setup() {
    const loading = ref(false)
    const loadingWithdraw = ref(false)
    const loadingWithdrawValue = ref(false)
    const loadingDeposit = ref(false)
    const loadingReason = ref(false)
    const loadingValidity = ref(false)
    const loadingGlobalStatPay = ref(false)
    const data = ref([])
    const cat = ref([])

    const componentKey = ref(0)
    const componentWithdrawKey = ref(0)
    const componentWithdrawValueKey = ref(0)
    const componentDepositValueKey = ref(0)
    const componentReasonKey = ref(0)
    const componentValidityKey = ref(0)
    const componentPayStatKey = ref(0)

    const newProjectOptions = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    const forceRerender = () => {
      componentKey.value += 1
    }

    const forceRerenderWithdrawChart = () => {
      componentWithdrawKey.value += 1
    }

    const forceRerenderWithdrawValueChart = () => {
      componentWithdrawValueKey.value += 1
    }

    const forceRerenderDepositValueChart = () => {
      componentDepositValueKey.value += 1
    }

    const forceRerenderReasonValueChart = () => {
      componentReasonKey.value += 1
    }

    const forceRerenderValidityValueChart = () => {
      componentValidityKey.value += 1
    }

    const forceRerenderGlobalPayStat = () => {
      componentPayStatKey.value += 1
    }

    const filterPay = event => {
      loading.value = true
      data.value = []
      cat.value = []
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterStat.min_date = event.date
          } else {
            store.state.payment.filterStat.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getPayChartData', store.state.payment.filterStat).then(() => {
        loading.value = false
        forceRerender()
      }).catch(error => {
        console.log(error)
        loading.value = false
      })
    }

    const filterBooking = event => {
      loadingDeposit.value = true
      data.value = []
      cat.value = []
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterDepositValueStat.min_date = event.date
          } else {
            store.state.payment.filterDepositValueStat.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getDepositValueChartData', store.state.payment.filterDepositValueStat).then(() => {
        loadingDeposit.value = false
        forceRerenderDepositValueChart()
      }).catch(error => {
        console.log(error)
        loadingDeposit.value = false
      })
    }

    const filterWithdrawValue = event => {
      loadingWithdrawValue.value = true
      data.value = []
      cat.value = []
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterWithdrawValueStat.min_date = event.date
          } else {
            store.state.payment.filterWithdrawValueStat.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getWithdrawValueChartData', store.state.payment.filterWithdrawValueStat).then(() => {
        loadingWithdrawValue.value = false
        forceRerenderWithdrawValueChart()
      }).catch(error => {
        console.log(error)
        loadingWithdrawValue.value = false
      })
    }

    const filterWithdraw = event => {
      loadingWithdraw.value = true
      data.value = []
      cat.value = []
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterWithdraw.min_date = event.date
          } else {
            store.state.payment.filterWithdraw.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getWithdrawChartData', store.state.payment.filterWithdraw).then(() => {
        loadingWithdraw.value = false
        forceRerenderWithdrawChart()
      }).catch(error => {
        console.log(error)
        loadingWithdraw.value = false
      })
    }

    const filterReason = event => {
      loadingReason.value = true
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterReason.min_date = event.date
          } else {
            store.state.payment.filterReason.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getReasonValueChartData', store.state.payment.filterReason).then(() => {
        loadingReason.value = false
        forceRerenderReasonValueChart()
      }).catch(error => {
        console.log(error)
        loadingReason.value = false
      })
    }

    const filterValidity = event => {
      loadingValidity.value = true
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterValidity.min_date = event.date
          } else {
            store.state.payment.filterValidity.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getValidityValueChartData', store.state.payment.filterValidity).then(() => {
        loadingValidity.value = false
        forceRerenderValidityValueChart()
      }).catch(error => {
        console.log(error)
        loadingValidity.value = false
      })
    }

    const getGlobalPayStat = () => {
      loadingGlobalStatPay.value = true
      store.dispatch('payment/getGlobalStatData').then(() => {
        loadingGlobalStatPay.value = false
        forceRerenderGlobalPayStat()
      }).catch(error => {
        loadingGlobalStatPay.value = false
        console.log(error)
      })
    }

    const initDashBoard = () => {
      setTimeout(() => {
        getGlobalPayStat()
        filterValidity()
        filterPay()
        filterBooking()
        filterWithdraw()
        filterWithdrawValue()
        filterReason()
      }, 1000)
    }

    watch(() => store.state.payment.filterValidity.refresh, () => {
      initDashBoard()
    }, { deep: true })

    onMounted(() => {
      initDashBoard()
    })

    return {
      getGlobalPayStat,
      loadingGlobalStatPay,
      componentPayStatKey,
      newProjectOptions,
      componentKey,
      filterValidity,
      loadingDeposit,
      filterWithdrawValue,
      filterBooking,
      filterReason,
      loadingWithdrawValue,
      componentDepositValueKey,
      data,
      cat,
      loading,
      loadingWithdraw,
      filterPay,
      filterWithdraw,
      componentWithdrawKey,
      componentWithdrawValueKey,
      componentReasonKey,
      loadingReason,
      loadingValidity,
      componentValidityKey,
      icons : {
        mdiCashPlus,
        mdiCashMinus
      }
    }
  },

}
</script>
