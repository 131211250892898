<template>
  <div>
    <v-skeleton-loader
      v-show="isLoaderVisible"
      boilerplate
      class="mx-auto"
      elevation="2"
      max-width="1200"
      type="table-row"
    ></v-skeleton-loader>
    <v-row v-show="!isLoaderVisible">
      <v-col
        cols="12"
        md="2"
        sm="6"
      >
        <statistic-card-custom
          :stat-title="totalUserOptions.statTitle"
          :color="totalUserOptions.color"
          :icon="totalUserOptions.icon"
          :statistics="totalUserOptions.statistics"
        ></statistic-card-custom>
      </v-col>
      <v-col
        cols="12"
        md="2"
        sm="6"
      >
        <statistic-card-custom
          :color="totalAdminOptions.color"
          :icon="totalAdminOptions.icon"
          :statistics="totalAdminOptions.statistics"
          :stat-title="totalAdminOptions.statTitle"
        ></statistic-card-custom>
      </v-col>
      <v-col
        cols="12"
        md="2"
        sm="6"
      >
        <statistic-card-custom
          :color="totalMemberOption.color"
          :icon="totalMemberOption.icon"
          :statistics="totalMemberOption.statistics"
          :stat-title="totalMemberOption.statTitle"
        ></statistic-card-custom>
      </v-col>
      <v-col
        cols="12"
        md="2"
        sm="6"
      >
        <statistic-card-custom
          :color="totalDriverOption.color"
          :icon="totalDriverOption.icon"
          :statistics="totalDriverOption.statistics"
          :stat-title="totalDriverOption.statTitle"
        ></statistic-card-custom>
      </v-col>
      <v-col
        cols="12"
        md="2"
        sm="6"
      >
        <statistic-card-custom
          :color="totalSubmittedOption.color"
          :icon="totalSubmittedOption.icon"
          :statistics="totalSubmittedOption.statistics"
          :stat-title="totalSubmittedOption.statTitle"
        ></statistic-card-custom>
      </v-col>
      <v-col
        cols="12"
        md="2"
        sm="6"
      >
        <statistic-card-custom
          :color="totalUnsubmittedOption.color"
          :icon="totalUnsubmittedOption.icon"
          :statistics="totalUnsubmittedOption.statistics"
          :stat-title="totalUnsubmittedOption.statTitle"
        ></statistic-card-custom>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiAccountGroup, mdiAccountSupervisor, mdiAccountGroupOutline, mdiCar, mdiFileQuestion, mdiFileCheck,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import { mapState } from 'vuex'
import StatisticCardCustom from '@core/components/statistics-card/StatisticCardCustom'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import store from '@/store'
import router from "@/router";

export default {
  name: 'UserStats',
  components: { LoaderDialog, StatisticCardCustom },

  computed: {
    ...mapState('user', ['stat']),
  },

  setup() {
    // transparent background card
    const totalUserOptions = {
      statTitle: 'Utilisateurs',
      statistics: store.state.user.stat.total_user,
      icon: mdiAccountGroup,
      color: 'primary',
    }

    // transparent background card
    const totalAdminOptions = {
      statTitle: 'Administrateurs',
      statistics: store.state.user.stat.total_admin,
      icon: mdiAccountSupervisor,
      color: 'success',
    }

    // transparent background card
    const totalMemberOption = {
      statTitle: 'Membres',
      statistics: store.state.user.stat.total_member,
      icon: mdiAccountGroupOutline,
      color: 'info',
    }

    // transparent background card
    const totalDriverOption = {
      statTitle: 'Chauffeurs',
      statistics: store.state.user.stat.total_driver,
      icon: mdiCar,
      color: 'warning',
    }

    const totalSubmittedOption = {
      statTitle: 'Soumisions',
      statistics: store.state.user.stat.total_submitted,
      icon: mdiFileCheck,
      color: 'info',
    }

    const totalUnsubmittedOption = {
      statTitle: 'Passagers',
      statistics: store.state.user.stat.total_unsubmitted,
      icon: mdiFileQuestion,
      color: 'secondary',
    }

    const isLoaderVisible = ref(false)

    const getStats = () => {
      isLoaderVisible.value = true
      store.dispatch('user/getStatistics').then(() => {
        totalUserOptions.statistics = store.state.user.stat.total_user
        totalDriverOption.statistics = store.state.user.stat.total_driver
        totalMemberOption.statistics = store.state.user.stat.total_member
        totalAdminOptions.statistics = store.state.user.stat.total_admin
        totalUnsubmittedOption.statistics = store.state.user.stat.total_unsubmitted
        totalSubmittedOption.statistics = store.state.user.stat.total_submitted
        isLoaderVisible.value = false
      }).catch(error => {
        console.log(error)
        isLoaderVisible.value = false
      })
    }

    onMounted(() => {
      getStats()
    })

    return {
      isLoaderVisible,
      totalUserOptions,
      totalDriverOption,
      totalMemberOption,
      totalAdminOptions,
      totalUnsubmittedOption,
      totalSubmittedOption,
    }
  },
}
</script>

<style scoped>

</style>
